import React from "react";
import { graphql } from "gatsby";
import { classNames } from "utils";
import marked from "marked";
import { useMediaQuery } from "react-responsive";

import HTMLContainer from "components/HTMLContainer";
import getBackground from "components/Backgrounds";
import getIllustration from "components/Illustrations";
import { COLOR_GRADIENT, CUBE_POSITIONS } from "shared/constants";

import styles from "./SectionContent.module.less";

import FloatingWhiteCube from "./floating_white_cube.svg";
import TransparentBlueCube from "./transparent_blue_cube.svg";
import ButtonsContainer from "./ButtonsContainer";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionContentDataFragment` below)
 */
export const SectionContentDataFragment = graphql`
  fragment SectionContentData on MarkdownRemarkFrontmatterSections {
    background
    illustration
    title
    content
    ...SectionContentButtonsData
    isInverse
    isHero
    transitioningCubes {
      largeCubePosition
      hasSmallCube
    }
    section_id
  }
`;

/**
 * @param {GatsbyTypes.SectionContentDataFragment} props
 */
export default function SectionContent({
  title,
  content,
  buttons,
  background,
  illustration,
  isInverse,
  isHero,
  transitioningCubes: { largeCubePosition, hasSmallCube },
  section_id,
}) {
  // Screen-Sm Ant default
  const isScreenSmall = useMediaQuery({ query: "(max-width: 576px)" });
  const isScreenSmallerThanMedium = useMediaQuery({
    query: "(max-width: 768px)",
  });

  // @ts-ignore
  const backgroundImage = getBackground(background);

  return (
    <section
      id={section_id}
      className={classNames(styles.sectionContent, {
        [styles.inverseSection]: Boolean(isInverse),
        [styles.hasCubes]: Boolean(largeCubePosition),
        [styles.isHero]: Boolean(isHero),
      })}
      style={
        !isScreenSmallerThanMedium
          ? backgroundImage && {
              backgroundImage: `url(${backgroundImage})${
                Boolean(isHero) ? `` : `, ${COLOR_GRADIENT}`
              }`,
            }
          : {}
      }
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>{title}</h2>
          <HTMLContainer
            html={marked(content)}
            className={styles.contentBody}
          />
          <ButtonsContainer buttons={buttons} />
        </div>

        {!isScreenSmallerThanMedium &&
          // @ts-ignore
          getIllustration(illustration, { isInverse })}

        {!isScreenSmall && largeCubePosition && (
          <div className={styles.cubes}>
            <img
              className={classNames(styles.floatingWhite, {
                [styles.rightOfContent]:
                  largeCubePosition === CUBE_POSITIONS.RIGHT_OF_CONTENT,
                [styles.leftOfContent]:
                  largeCubePosition === CUBE_POSITIONS.LEFT_OF_CONTENT,
                [styles.rightOfIllustration]:
                  largeCubePosition === CUBE_POSITIONS.RIGHT_OF_ILLUSTRATION,
              })}
              src={FloatingWhiteCube}
              alt="Floating White Cube"
            />
            {hasSmallCube && (
              <img
                className={classNames(styles.transparentBlue, {
                  [styles.rightOfContent]:
                    largeCubePosition === CUBE_POSITIONS.RIGHT_OF_CONTENT,
                  [styles.leftOfContent]:
                    largeCubePosition === CUBE_POSITIONS.LEFT_OF_CONTENT,
                  [styles.rightOfIllustration]:
                    largeCubePosition === CUBE_POSITIONS.RIGHT_OF_ILLUSTRATION,
                })}
                src={TransparentBlueCube}
                alt="Transparent Blue Cube"
              />
            )}
          </div>
        )}
      </div>
    </section>
  );
}
