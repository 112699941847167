import React from "react";
import { Carousel as AntCarousel, Button } from "antd";

import { classNames } from "utils";
import getIcon from "components/Icons";

import styles from "./Carousel.module.less";

const NAV_ICON_SIZE = { width: 16, height: 24 };

/**
 * @typedef {object} AdditionalProps
 * @prop {boolean} [withNavigation]
 * @prop {boolean} [withBoxShadow]
 * @prop {boolean} [withCircleCorners]
 *
 * @param {React.HTMLAttributes & AdditionalProps} props
 */
export default function Carousel({
  children,
  className,
  withNavigation = false,
  withBoxShadow = false,
  withCircleCorners = false,
}) {
  return (
    <div className={styles.carouselContainer}>
      <AntCarousel
        className={classNames(
          styles.carousel,
          {
            [styles.withBoxShadow]: withBoxShadow,
            [styles.withCircleCorners]: withCircleCorners,
          },
          className
        )}
        swipeToSlide={true}
        arrows={withNavigation}
        nextArrow={
          <Button
            type="primary"
            shape="circle"
            icon={getIcon("chevronRight", NAV_ICON_SIZE)}
            size={"large"}
          />
        }
        prevArrow={
          <Button
            type="primary"
            shape="circle"
            icon={getIcon("chevronLeft", NAV_ICON_SIZE)}
            size={"large"}
          />
        }
        autoplay
        speed={1000}
        autoplaySpeed={9500}
        cssEase="linear"
      >
        {children}
      </AntCarousel>
    </div>
  );
}
