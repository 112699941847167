import React from "react";

/**
 * @typedef {object} HTMLContainerProps
 * @prop {React.ReactElement[] | string} html
 * @prop {React.ElementType} [component]
 *
 * @param {React.HTMLAttributes & HTMLContainerProps} props
 */
export default function HTMLContainer({
  component: Component = "div",
  html,
  ...props
}) {
  if (typeof html === "string") {
    return <Component {...props} dangerouslySetInnerHTML={{ __html: html }} />;
  }

  return <Component {...props}>{html}</Component>;
}
