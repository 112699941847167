import React from "react";
import { graphql } from "gatsby";
import marked from "marked";
import { Row, Col } from "antd";

import HTMLContainer from "components/HTMLContainer";

import styles from "./SectionContentCards.module.less";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionContentCardsDataFragment` below)
 */
export const SectionContentCardsDataFragment = graphql`
  fragment SectionContentCardsData on MarkdownRemarkFrontmatterSections {
    title
    cards {
      title
      body
      halfSize
    }
    section_id
  }
`;

/**
 * @param {GatsbyTypes.SectionContentCardsDataFragment} props
 */
export default function SectionContentCards(props) {
  const { title, cards, section_id } = props;
  return (
    <section id={section_id} className={styles.container}>
      <h2 className={styles.sectionTitle}>{title}</h2>
      <Row
        gutter={{
          sm: 12,
          md: 32, // 2rem
        }}
        justify="center"
      >
        {cards.map(({ title, body, halfSize }, idx) => (
          <Col key={idx} xs={24} lg={halfSize ? 12 : 24}>
            <div className={styles.cardContainer}>
              <h3 className={styles.cardTitle}>{title}</h3>
              <HTMLContainer
                html={marked(body)}
                className={styles.cardContent}
              />
            </div>
          </Col>
        ))}
      </Row>
    </section>
  );
}
