import React from "react";
import { graphql } from "gatsby";
import marked from "marked";
import HTMLContainer from "components/HTMLContainer";

import getBackground from "components/Backgrounds";
import { COLOR_GRADIENT } from "shared/constants";

import styles from "./SectionEbooks.module.less";
import { LinkButton } from "components/Buttons/Buttons";
import getIcon from "components/Icons";
import Image from "components/Image";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionContentDataFragment` below)
 */
export const SectionEbooksDataFragment = graphql`
  fragment SectionEbooksData on MarkdownRemarkFrontmatterSections {
    background
    title
    content
    ebooks {
      ...EbookData
    }
    section_id
  }
`;

/**
 * @param {GatsbyTypes.SectionEbooksDataFragment} props
 */
export default function SectionEbooks({
  background,
  title,
  content,
  ebooks,
  section_id,
}) {
  // @ts-ignore
  const backgroundImage = getBackground(background);

  return (
    <section
      id={section_id}
      className={styles.sectionEbooks}
      style={
        backgroundImage && {
          backgroundImage: `url(${backgroundImage}), ${COLOR_GRADIENT}`,
        }
      }
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>{title}</h2>
          {content && (
            <div className={styles.contentHTML}>
              <HTMLContainer
                html={marked(content)}
                className={styles.contentBody}
              />
            </div>
          )}
          <div>
            {ebooks.map((ebook, index) => (
              <Ebook key={`Ebook-${index}`} {...ebook} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionContentDataFragment` below)
 */
export const EbookDataFragment = graphql`
  fragment EbookData on MarkdownRemarkFrontmatterSectionsEbooks {
    title
    description
    image {
      image {
        childImageSharp {
          fixed(width: 192, height: 192) {
            ...GatsbyImageSharpFixed
          }
        }
        ...FileInformation
      }
      alt
      caption
    }
    formURL
  }
`;

/**
 * @param {GatsbyTypes.EbookDataFragment} props
 */
function Ebook({ title, description, formURL, image: imageOrNull }) {
  const { image, alt, caption } = imageOrNull || {
    image: null,
    alt: "",
    caption: "",
  };

  return (
    <div className={styles.ebook}>
      <EbookThumbnail
        image={/** @type {GatsbyTypes.File} */ (image)}
        alt={alt || title}
        caption={caption || title}
      />
      <div className={styles.ebookInfo}>
        <h3>{title}</h3>
        {description && (
          <HTMLContainer
            html={marked(description)}
            className={styles.contentBody}
          />
        )}
        <LinkButton
          className={styles.downloadButton}
          to={formURL}
          title="Download"
          icon={getIcon("arrowForward", { width: 20 })}
        />
      </div>
    </div>
  );
}

/**
 * @typedef {object} EbookThumbnailProps
 * @prop {GatsbyTypes.File | string} image
 * @prop {string} alt
 * @prop {string} caption
 *
 * @param {EbookThumbnailProps} props
 */
function EbookThumbnail({ image, alt, caption }) {
  if (
    typeof window !== "undefined" &&
    // @ts-ignore
    window.NETLIFY_CMS &&
    typeof image === "string"
  ) {
    return (
      <Image
        className={styles.image}
        src={null}
        title={caption}
        alt={alt}
        publicURL={image}
      />
    );
  }

  const {
    childImageSharp: coverImage,
    publicURL,
  } = /** @type {GatsbyTypes.File} */ (image) || {
    childImageSharp: null,
    publicURL: null,
  };

  if (!coverImage) {
    return null;
  }

  return (
    <Image
      className={styles.image}
      src={coverImage}
      title={caption}
      alt={alt}
      publicURL={publicURL}
    />
  );
}
