import React from "react";
import dayjs from "dayjs";
import { graphql } from "gatsby";

import BlogPostTags from "./BlogPostTags";
import Image from "components/Image";
import { Link } from "utils";

import styles from "./BlogPostThumbnail.module.less";

// Blogpost Fragments
export const BlogPostThumbnailFragment = graphql`
  fragment BlogPostThumbnail on MarkdownRemark {
    # Leave the "id" even if not consumed, used by search
    id
    fields {
      path
      # Leave dateModified
      dateModified
    }
    frontmatter {
      title
      date
      tags
      thumbnail {
        image {
          thumbnailImage: childImageSharp {
            fluid(
              maxWidth: 355
              maxHeight: 235
              cropFocus: CENTER
              quality: 90
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        alt
        caption
      }
    }
  }
`;

/**
 * @param {GatsbyTypes.BlogPostThumbnailFragment} props
 */
export default function BlogPostThumbnail(props) {
  const {
    fields: { path },
    frontmatter: { title, date, tags, thumbnail },
  } = props;

  const image = thumbnail.image ? (
    <Image
      src={thumbnail.image.thumbnailImage}
      title={thumbnail.caption || title}
      alt={thumbnail.alt || title}
      publicURL={thumbnail.image.publicURL}
      className={styles.mediaImage}
    />
  ) : null;

  const headerElement = <h3 className={styles.title}>{title}</h3>;

  return (
    <article className={styles.thumbnail}>
      {path ? <Link to={path}>{image}</Link> : image}
      <div className={styles.content}>
        {/* TODO: Change header element when standard header sizes are defined */}
        {path ? <Link to={path}>{headerElement}</Link> : headerElement}
        <div className={styles.metaData}>
          <time dateTime={dayjs(date).format("YYYY-MM-DD HH:mm")}>
            {dayjs(date).format("MMMM DD, YYYY")}
          </time>
          {" - "}
          <BlogPostTags tags={tags} />
        </div>
      </div>
    </article>
  );
}
