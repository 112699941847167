import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { LinkButton } from "components/Buttons";
import getIcon from "components/Icons";
import Image from "components/Image";

import styles from "./ClientCase.module.less";
import { classNames } from "utils";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.ClientCaseDataFragment` below)
 */
export const ClientCaseDataFragment = graphql`
  fragment ClientCaseData on MarkdownRemarkFrontmatterSectionsCases {
    companyName
    description
    articleURL
    image {
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        ...FileInformation
      }
      alt
      caption
    }
  }
`;

export const ClientCasesStaticSectionDataFragment = graphql`
  fragment ClientCasesStaticSectionData on MarkdownRemarkFrontmatter {
    title
    articleLink {
      linkText
      linkIcon
    }
  }
`;

/**
 * @param {GatsbyTypes.ClientCaseDataFragment} props
 */
export default function ClientCase(props) {
  // @ts-ignore
  if (typeof window !== "undefined" && window.NETLIFY_CMS) {
    return <ClientCaseTemplate {...props} />;
  }
  return <ClientCaseComponent {...props} />;
}

function ClientCaseComponent(props) {
  /** @type {GatsbyTypes.SectionClientCaseQuery} */
  const {
    clientCasesData: {
      frontmatter: {
        title,
        articleLink: { linkText, linkIcon },
      },
    },
  } = useStaticQuery(graphql`
    query SectionClientCase {
      clientCasesData: markdownRemark(
        frontmatter: {
          identifier: { eq: "clientCases" }
          type: { eq: "staticSection" }
        }
      ) {
        frontmatter {
          ...ClientCasesStaticSectionData
        }
      }
    }
  `);

  return (
    <ClientCaseTemplate
      {...props}
      title={title}
      linkText={linkText}
      linkIcon={linkIcon}
    />
  );
}

/**
 * @typedef {object} AdditionalProps
 * @prop {string} [title]
 * @prop {string} [linkText]
 * @prop {string} [linkIcon]
 *
 * @param {GatsbyTypes.ClientCaseDataFragment & AdditionalProps} props
 */
function ClientCaseTemplate({
  companyName,
  description,
  articleURL,
  image: imageOrNull,
  title = "Client case /",
  linkText = "Read the full story here",
  linkIcon = "arrowForward",
}) {
  const { image, alt, caption } = imageOrNull || {
    image: null,
    alt: "",
    caption: "",
  };

  return (
    <div className={styles.clientCase}>
      <div className={styles.textContainer}>
        <h4 className={styles.name}>
          <span className={styles.title}>{title}</span>
          <span>{companyName}</span>
        </h4>
        <p>{description}</p>
        <LinkButton
          to={articleURL}
          isContent
          title={linkText}
          // @ts-ignore
          icon={getIcon(linkIcon, { width: 30 })}
        />
      </div>
      <ClientCaseImage
        image={/** @type {GatsbyTypes.File} */ (image)}
        alt={alt || companyName}
        caption={caption || companyName}
      />
    </div>
  );
}
/**
 * @typedef {object} ClientCaseImageProps
 * @prop {string | GatsbyTypes.File} image
 * @prop {string} alt
 * @prop {string} caption
 *
 * @param {ClientCaseImageProps} props
 */
function ClientCaseImage({ image, alt, caption }) {
  if (typeof image === "string") {
    return (
      <Image
        className={classNames(styles.image, styles.cmsImage)}
        src={null}
        title={caption}
        alt={alt}
        publicURL={image}
      />
    );
  }

  const {
    childImageSharp: clientCaseImage,
    publicURL,
  } = /** @type {GatsbyTypes.File} */ (image) || {
    childImageSharp: null,
    publicURL: null,
  };

  if (!clientCaseImage) {
    return null;
  }

  return (
    <Image
      className={styles.image}
      src={clientCaseImage}
      title={caption}
      alt={alt}
      publicURL={publicURL}
    />
  );
}
