import TopRightCubes from "assets/backgrounds/top_right_cubes.svg";
import HeaderCubes from "assets/backgrounds/header_cubes.svg";
import NewClients from "assets/backgrounds/new_clients.svg";
import DigitalTransformation from "assets/backgrounds/digital_transformation.svg";
import KnowledgeSharing from "assets/backgrounds/knowledge_sharing.svg";
import LeftFloatingCubes from "assets/backgrounds/left_floating_cubes.svg";
import Security from "assets/backgrounds/security.svg";
import Privacy from "assets/backgrounds/privacy.svg";
// @ts-ignore
import HeroImage from "assets/backgrounds/hero_image-cropped.png";

const backgrounds = {
  digitalTransformation: {
    label: "Digital Transformation",
    svg: DigitalTransformation,
  },
  headerCubes: {
    label: "Header Cubes",
    svg: HeaderCubes,
  },
  heroImage: {
    label: "Hero Image",
    svg: HeroImage,
  },
  knowledgeSharing: {
    label: "Knowledge Sharing",
    svg: KnowledgeSharing,
  },
  leftFloatingCubes: {
    label: "Left Floating Cubes",
    svg: LeftFloatingCubes,
  },
  newClients: {
    label: "New Clients",
    svg: NewClients,
  },
  privacy: {
    label: "Privacy",
    svg: Privacy,
  },
  security: {
    label: "Security",
    svg: Security,
  },
  topRightCubes: {
    label: "Top Right Cubes",
    svg: TopRightCubes,
  },
};

export const backgroundOptions = Object.entries(backgrounds).map(
  ([key, { label }]) => ({
    label,
    value: key,
  })
);

/** @typedef {keyof backgrounds} BackgroundsAvailable  */

/**
 * @param {BackgroundsAvailable} [id]
 **/
export default function getBackground(id) {
  return backgrounds[id] && backgrounds[id].svg;
}
