import React from "react";
import { graphql } from "gatsby";
import { Row, Col } from "antd";

import Image from "components/Image";
import HTMLContainer from "components/HTMLContainer";

import styles from "./SectionLeadership.module.less";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionLeadershipDataFragment` below)
 */
export const SectionLeadershipDataFragment = graphql`
  fragment PeopleThumbnail on MarkdownRemark {
    id
    html
    frontmatter {
      name
      title
      avatar {
        image {
          ...AvatarImage
        }
        alt
        caption
      }
    }
  }

  fragment SectionLeadershipData on MarkdownRemarkFrontmatterSections {
    title
    peopleList {
      ...PeopleThumbnail
    }
    section_id
  }
`;

/**
 * @param {GatsbyTypes.SectionLeadershipDataFragment} props
 */
export default function SectionLeadership({ title, peopleList, section_id }) {
  return (
    <section id={section_id} className={styles.container}>
      <div className={styles.contentContainer}>
        <h2 className={styles.sectionTitle}>{title}</h2>
        <Row
          gutter={{
            sm: 12,
            md: 32, // 2rem
          }}
          justify="center"
        >
          {peopleList.map((person, index) => {
            const id = person.id || `person-${index}`;
            return (
              <Col key={id} xs={24} sm={12} lg={8}>
                <PeopleCard id={id} person={person} />
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}

/**
 * @param {{ id: string; person: GatsbyTypes.PeopleThumbnailFragment | string;}} props
 */
const PeopleCard = ({ person }) => {
  if (
    typeof window !== "undefined" &&
    // @ts-ignore
    window.NETLIFY_CMS &&
    typeof person === "string"
  ) {
    return (
      <div className={styles.cardContainer}>
        <div className={styles.name}>{person}</div>
        <div className={styles.title}>Placeholder</div>
        <div className={styles.avatar}>
          <Image
            className={styles.placeholderAvatarImage}
            src={null}
            title={"caption"}
            alt={"alt"}
            publicURL={`https://via.placeholder.com/850x850.png?text=${person}'s+avatar`}
          />
        </div>
        <HTMLContainer
          className={styles.bio}
          html={`This is a placeholder component. You can edit the content in the "People" collection.`}
        />
      </div>
    );
  }

  const {
    html,
    frontmatter: { avatar, name, title },
  } = /** @type {GatsbyTypes.PeopleThumbnailFragment} */ (person);

  const { image, alt, caption } = avatar || {};

  const { avatar: avatarImage, publicURL } = image || {
    avatar: null,
    publicURL: null,
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.name}>{name}</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.avatar}>
        <Image
          src={avatarImage}
          title={caption}
          alt={alt}
          publicURL={publicURL}
        />
      </div>
      <HTMLContainer className={styles.bio} html={html} />
    </div>
  );
};
