import React from "react";
import { classNames } from "utils";

import styles from "./FeatureCard.module.less";

/**
 * @typedef {object} FeatureCardProps
 * @prop {boolean} [inverted]
 * @prop {React.ReactNode} [icon]
 * @prop {string} title
 * @prop {string} description
 *
 * @param {React.HTMLAttributes & FeatureCardProps} props
 */
export default function FeatureCard({
  inverted = false,
  icon = null,
  title,
  description,
  className,
}) {
  return (
    <div
      className={classNames(styles.card, className, {
        [styles.inverted]: inverted,
      })}
    >
      {icon}
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}
