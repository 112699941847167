import React from "react";

import Graph from "assets/illustrations/graph.svg";
import Compliance from "assets/illustrations/compliance.svg";
import ComputerWithGraphs from "assets/illustrations/computer_with_graphs.svg";
import Key from "assets/illustrations/key.svg";
import InexOneLogo from "assets/illustrations/inex_one_logo.svg";
// @ts-ignore
import Soc2Logo from "assets/illustrations/soc2.png";

import styles from "./Illustrations.module.less";
import { classNames } from "utils";
import SecurityList from "./SecurityList";

const illustrations = {
  compliance: {
    label: "Compliance",
    className: styles.compliance,
    inverseClassName: styles.inverseCompliance,
    image: Compliance,
  },
  computerWithGraphs: {
    label: "Computer With Graphs",
    className: styles.computerWithGraphs,
    inverseClassName: styles.inverseComputerWithGraphs,
    image: ComputerWithGraphs,
  },
  graph: {
    label: "Graph",
    className: styles.graph,
    inverseClassName: styles.inverseGraph,
    image: Graph,
  },
  inexOneLogo: {
    label: "Inex One Logo",
    className: styles.inexOneLogo,
    // inverseClassName: styles.inverseInexOneLogo,
    image: InexOneLogo,
  },
  key: {
    label: "Key",
    className: styles.key,
    inverseClassName: styles.inverseKey,
    image: Key,
  },
  securityList: {
    label: "List of Security features",
    // className: styles.securityList,
    // inverseClassName: styles.inverseSecurityList,
    component: <SecurityList />,
  },
  soc2Logo: {
    label: "SOC2 Logo",
    className: styles.soc2Logo,
    // inverseClassName: styles.inverseSoc2Logo,
    image: Soc2Logo,
  },
};

export const illustrationOptions = Object.entries(illustrations).map(
  ([key, { label }]) => ({
    label,
    value: key,
  })
);

/** @typedef {keyof illustrations} IllustrationsAvailable  */

/**
 * @param {IllustrationsAvailable} [id]
 * @param {object} [options]
 * @param {boolean} [options.isInverse]
 **/
export default function getIllustration(id, { isInverse }) {
  const illustration = illustrations[id];

  return illustration ? (
    <div className={styles.illustration}>
      {illustration && illustration.component ? (
        illustration.component
      ) : (
        <img
          className={classNames(illustration.className, {
            [illustration.inverseClassName]: isInverse,
          })}
          src={illustration.image}
          alt={illustration.label}
        />
      )}
    </div>
  ) : null;
}
