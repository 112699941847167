import React from "react";

import getIcon from "components/Icons";

import CubeSvg from "./cube.svg";

import styles from "./SecurityList.module.less";

export default function SecurityList() {
  return (
    <div className={styles.securityList}>
      <ul className={styles.list}>
        {[
          "Admin controls",
          "Domain verification",
          "SAML-based SSO",
          "Data encryption",
          "Vulnerability scanning",
        ].map((listItemText, index) => (
          <li key={`ListItem-${index}`}>
            {getIcon("check", { width: 32 })}
            {listItemText}
          </li>
        ))}
      </ul>
      <img className={styles.cubeImage} src={CubeSvg} alt="Cube" />
    </div>
  );
}
