import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import BlogPostThumbnail from "components/BlogPostThumbnail";
import { LinkButton } from "components/Buttons";
import getIcon from "components/Icons";

import styles from "./SectionBlogSuggestions.module.less";

const BLOGPOST_FIELDS = {
  fields: { path: null, dateModified: new Date().toISOString() },
  thumbnail: {
    image: {
      thumbnailImage: null,
      publicURL:
        "https://via.placeholder.com/355x235.png?text=Placeholder+Image",
      extension: "png",
    },
    alt: "Placeholder image",
    caption: "Placeholder image",
  },
  date: new Date().toISOString(),
};

const BLOGPOSTS_PLACEHOLDER = [
  {
    id: "1",
    fields: BLOGPOST_FIELDS.fields,
    frontmatter: {
      title: "Most recent post",
      date: BLOGPOST_FIELDS.date,
      tags: ["News"],
      thumbnail: BLOGPOST_FIELDS.thumbnail,
    },
  },
  {
    id: "2",
    fields: BLOGPOST_FIELDS.fields,
    frontmatter: {
      title: "Second most recent post",
      date: BLOGPOST_FIELDS.date,
      tags: ["News"],
      thumbnail: BLOGPOST_FIELDS.thumbnail,
    },
  },
  {
    id: "3",
    fields: BLOGPOST_FIELDS.fields,
    frontmatter: {
      title: "Third most recent post",
      date: BLOGPOST_FIELDS.date,
      tags: ["News"],
      thumbnail: BLOGPOST_FIELDS.thumbnail,
    },
  },
];

/**
 * @typedef {object} SectionBlogSuggestionsProps
 * @prop {GatsbyTypes.BlogPostThumbnailFragment[]} [blogPosts]
 * @prop {string} [section_id]
 * @param {SectionBlogSuggestionsProps} props
 */
export default function SectionBlogSuggestions(props) {
  // @ts-ignore
  if (typeof window !== "undefined" && window.NETLIFY_CMS) {
    return (
      <SectionBlogSuggestionsTemplate
        {...props}
        recentBlogPosts={BLOGPOSTS_PLACEHOLDER}
        title="Inex One insights"
        subtitle={`This is a placeholder text. Edit the actual component under "Reusable sections/Blog Suggestions"`}
        linkText="See all"
        linkIcon="arrowForward"
      />
    );
  }
  return <SectionBlogSuggestionsComponent {...props} />;
}

/**
 * @param {SectionBlogSuggestionsProps} props
 */
function SectionBlogSuggestionsComponent(props) {
  /** @type {GatsbyTypes.SectionBlogSuggestionsQuery} */
  const {
    blogSuggestionsData: {
      frontmatter: {
        title,
        subtitle,
        blogLink: { linkIcon, linkText },
      },
    },
    recentPosts: { edges: data },
  } = useStaticQuery(graphql`
    query SectionBlogSuggestions {
      blogSuggestionsData: markdownRemark(
        frontmatter: {
          identifier: { eq: "blogSuggestions" }
          type: { eq: "staticSection" }
        }
      ) {
        frontmatter {
          title
          subtitle
          blogLink {
            linkText
            linkIcon
          }
        }
      }
      # Last 3 posts for thumbnail
      recentPosts: allMarkdownRemark(
        limit: 3
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { template: { eq: "post" } } }
      ) {
        edges {
          node {
            ...BlogPostThumbnail
          }
        }
      }
    }
  `);

  const recentBlogPosts = data ? data.map(({ node }) => node) : [];

  return (
    <SectionBlogSuggestionsTemplate
      {...props}
      recentBlogPosts={recentBlogPosts}
      title={title}
      subtitle={subtitle}
      linkIcon={linkIcon}
      linkText={linkText}
    />
  );
}

/**
 * @typedef {object} StaticQueryProps
 * @prop {GatsbyTypes.BlogPostThumbnailFragment[]} recentBlogPosts
 * @prop {string} title
 * @prop {string} subtitle
 * @prop {string} linkText
 * @prop {string} linkIcon
 *
 * @param {SectionBlogSuggestionsProps & StaticQueryProps} props
 */
function SectionBlogSuggestionsTemplate({
  blogPosts = [],
  section_id = "insights",
  recentBlogPosts,
  title,
  subtitle,
  linkText,
  linkIcon,
}) {
  /** Get 3 different items, blogPosts prioritized */
  const displayedPosts = [...blogPosts, ...recentBlogPosts].reduce(
    (acc, curr) => {
      if (acc.length < 3 && acc.findIndex(({ id }) => id === curr.id) < 0) {
        acc.push(curr);
      }
      return acc;
    },
    []
  );

  return (
    <section id={section_id} className={styles.blogSuggestions}>
      <div className={styles.content}>
        <div className={styles.textBlock}>
          <h2>{title}</h2>
          <p>{subtitle}</p>
          <LinkButton
            to="/blog"
            isContent
            title={linkText}
            // @ts-ignore
            icon={getIcon(linkIcon, { width: 30 })}
          />
        </div>
        <div className={styles.blogPostThumbnailsContainer}>
          {displayedPosts.map((post, index) => (
            <div
              key={`suggestion-thumbnail-${index}`}
              className={styles.postThumbnailContainer}
            >
              <BlogPostThumbnail {...post} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
