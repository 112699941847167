import React from "react";
import queryString from "query-string";

import { Link } from "utils";

const getCategoryLink = (category) =>
  "/blog?" + queryString.stringify({ category });

/**
 * @param {object} props
 * @param {readonly string[]} [props.tags]
 */
export default function BlogPostTags(props) {
  const { tags } = props;

  return (
    <span>
      {tags.map((tag, index) => (
        <span key={tag}>
          <Link to={getCategoryLink(tag)}>{tag}</Link>
          {index < tags.length - 1 ? ", " : null}
        </span>
      ))}
    </span>
  );
}
