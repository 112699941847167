import React from "react";
import { graphql } from "gatsby";
import marked from "marked";
import { Collapse } from "antd";

import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import HTMLContainer from "components/HTMLContainer";

import styles from "./SectionCollapsible.module.less";

const { Panel } = Collapse;

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionCollapsibleDataFragment` below)
 */
export const SectionCollapsibleDataFragment = graphql`
  fragment SectionCollapsibleData on MarkdownRemarkFrontmatterSections {
    title
    cards {
      title
      body
    }
    section_id
  }
`;
/**
 * @param {GatsbyTypes.SectionCollapsibleDataFragment} props
 */
export default function SectionCollapsible({ title, cards, section_id }) {
  return (
    <section id={section_id} className={styles.container}>
      <h2 className={styles.sectionTitle}>{title}</h2>
      <Collapse
        className={styles.collapseContainer}
        defaultActiveKey={[0]}
        expandIcon={(panelProps) =>
          panelProps.isActive ? <MinusOutlined /> : <PlusOutlined />
        }
        expandIconPosition="right"
      >
        {cards.map((card, idx) => (
          <Panel header={card.title} key={idx}>
            <HTMLContainer html={marked(card.body)} />
          </Panel>
        ))}
      </Collapse>
    </section>
  );
}
