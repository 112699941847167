import React from "react";
import { graphql } from "gatsby";
import { classNames } from "utils";

import getIcon from "components/Icons";

import styles from "./Quote.module.less";

const STYLE_MAP = {
  center: styles.alignCenter,
  right: styles.alignRight,
  left: undefined,
};

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.TestimonialDataFragment` below)
 */
export const TestimonialDataFragment = graphql`
  fragment TestimonialData on MarkdownRemarkFrontmatterSectionsQuotes {
    text
    person {
      name
      title
    }
  }
`;

/**
 * @typedef {object} AdditionalProps
 * @prop {keyof STYLE_MAP} [align]
 *
 * @param {GatsbyTypes.TestimonialDataFragment & AdditionalProps} params
 */
export default function Quote({ align = "left", text, person }) {
  return (
    <div className={classNames(styles.quote, STYLE_MAP[align])}>
      {getIcon("quoteMarks", { width: 40, height: 32 })}
      <div className={styles.text}>{text}</div>
      {person && (
        <div className={styles.caption}>
          <span>{person.name}</span>
          <span className={styles.title}>{`, ${person.title}`}</span>
        </div>
      )}
    </div>
  );
}
