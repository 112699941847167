import React from "react";
import { graphql } from "gatsby";
import { useMediaQuery } from "react-responsive";

import styles from "./SectionHero.module.less";
import ButtonsContainer from "../ButtonsContainer";
import getBackground from "components/Backgrounds";

const LARGE_GRADIENT = `linear-gradient(rgb(255, 255, 255) 30.82%, rgba(255, 255, 255, 0) 45.27%)`;
const DESKTOP_GRADIENT = `linear-gradient(
  180deg,
  #ffffff 22.82%,
  rgba(255, 255, 255, 0) 42.27%
)`;
const TABLET_GRADIENT = `linear-gradient(rgb(255, 255, 255) 42.82%, rgba(255, 255, 255, 0) 64.27%)`;
const MOBILE_GRADIENT = `linear-gradient(rgb(255, 255, 255) 56.82%, rgba(255, 255, 255, 0) 73.27%)`;

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionContentDataFragment` below)
 */
export const SectionHeroDataFragment = graphql`
  fragment SectionHeroData on MarkdownRemarkFrontmatterSections {
    background
    title
    preamble
    ...SectionContentButtonsData
    section_id
  }
`;

/**
 * @param {GatsbyTypes.SectionHeroDataFragment} props
 */
export default function SectionHero({
  background,
  title,
  preamble,
  buttons,
  section_id,
}) {
  // ScreenAnt defaults
  const isScreenSmall = useMediaQuery({ query: "(max-width: 576px)" });
  const isScreenMedium = useMediaQuery({ query: "(max-width: 768px)" });
  const isScreenXXLarge = useMediaQuery({ query: "(min-width: 1600px)" });

  const gradient = isScreenSmall
    ? MOBILE_GRADIENT
    : isScreenMedium
    ? TABLET_GRADIENT
    : isScreenXXLarge
    ? LARGE_GRADIENT
    : DESKTOP_GRADIENT;

  return (
    <section
      id={section_id}
      className={styles.sectionHero}
      style={{
        backgroundImage:
          gradient +
          `, ` +
          // @ts-ignore
          `url("${getBackground(background)}")`,
      }}
    >
      <div className={styles.content}>
        <h1>{title}</h1>
        <p>{preamble}</p>
        <ButtonsContainer buttons={buttons} />
      </div>
    </section>
  );
}
