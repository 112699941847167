import React from "react";
import { graphql } from "gatsby";

import styles from "./SectionContent.module.less";
import getIcon from "components/Icons";
import { BUTTON_TYPES } from "shared/constants";
import { SecondaryButton, LinkButton, PrimaryButton } from "components/Buttons";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionContentDataFragment` below)
 */
export const SectionContentButtonsDataFragment = graphql`
  fragment SectionContentButtonsData on MarkdownRemarkFrontmatterSections {
    buttons {
      type
      title
      icon
      url
      fullWidth
    }
  }
`;

/**
 * @param {GatsbyTypes.SectionContentButtonsDataFragment} props
 */
export default function ButtonsContainer({ buttons }) {
  return (
    <div className={styles.buttonsContainer}>
      {buttons &&
        buttons.map(({ type, title, icon, url, fullWidth }, index) => {
          const buttonProps = {
            to: url,
            title,
            // @ts-ignore
            icon: icon && getIcon(icon, { width: 30 }),
            fullWidth,
          };
          switch (type) {
            case BUTTON_TYPES.SECONDARY:
              return (
                <SecondaryButton
                  key={`button-${index}`}
                  hasBoxShadow
                  isContent
                  {...buttonProps}
                />
              );
            case BUTTON_TYPES.LINK:
              return (
                <LinkButton
                  key={`button-${index}`}
                  isContent
                  {...buttonProps}
                />
              );
            case BUTTON_TYPES.PRIMARY:
            default:
              return (
                <PrimaryButton
                  key={`button-${index}`}
                  hasBoxShadow
                  isContent
                  {...buttonProps}
                />
              );
          }
        })}
    </div>
  );
}
