import React from "react";
import { graphql } from "gatsby";

import getIcon from "components/Icons";
import FeatureCard from "./FeatureCard";

import styles from "./SectionFeatures.module.less";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionFeatureDataFragment` below)
 */
export const SectionFeatureDataFragment = graphql`
  fragment SectionFeatureData on MarkdownRemarkFrontmatterSections {
    cards {
      icon
      title
      description
    }
    section_id
  }
`;

/**
 * @param {GatsbyTypes.SectionFeatureDataFragment} props
 */
export default function SectionFeature({ cards, section_id }) {
  return (
    <section id={section_id} className={styles.featureSection}>
      {cards.slice(0, 3).map(({ icon, title, description }, index) => {
        const cardProps = {
          key: `feature-card-${index}`,
          // @ts-ignore
          icon: getIcon(icon, { width: 100, height: 100 }),
          title,
          description,
        };

        switch (index) {
          case 0:
            cardProps.className = styles.firstCard;
            break;
          case 1:
            cardProps.className = styles.middleCard;
            cardProps.inverted = true;
            break;
          case 2:
            cardProps.className = styles.endCard;
            break;
          default:
            break;
        }

        return <FeatureCard {...cardProps} />;
      })}
    </section>
  );
}
