import React from "react";
import { graphql } from "gatsby";

import Carousel from "components/Carousel";
import ClientCase from "./ClientCase";

import styles from "./SectionClientCases.module.less";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionClientCasesDataFragment` below)
 */
export const SectionClientCasesDataFragment = graphql`
  fragment SectionClientCasesData on MarkdownRemarkFrontmatterSections {
    cases {
      ...ClientCaseData
    }
    section_id
  }
`;

/**
 * @param {GatsbyTypes.SectionClientCasesDataFragment} props
 */
export default function SectionClientCase({ cases, section_id }) {
  return (
    <section id={section_id} className={styles.clientCaseContainer}>
      <Carousel withNavigation withBoxShadow withCircleCorners>
        {cases.map((caseItem, index) => (
          <ClientCase key={`${section_id}-${index}`} {...caseItem} />
        ))}
      </Carousel>
    </section>
  );
}
