import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col } from "antd";

import Carousel from "components/Carousel";

import Quote from "components/Quote";
import Image from "components/Image";

import styles from "./SectionTestimonials.module.less";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionTestimonialsDataFragment` below)
 */
export const SectionTestimonialsDataFragment = graphql`
  fragment SectionTestimonialsData on MarkdownRemarkFrontmatterSections {
    quotes {
      ...TestimonialData
    }
    section_id
  }
`;

/**
 * @param {GatsbyTypes.SectionTestimonialsDataFragment} props
 */
export default function SectionTestimonials(props) {
  // @ts-ignore
  if (typeof window !== "undefined" && window.NETLIFY_CMS) {
    return <SectionTestimonialsTemplate {...props} companyLogos={[]} />;
  }
  return <SectionTestimonialsComponent {...props} />;
}

/**
 * @param {GatsbyTypes.SectionTestimonialsDataFragment} props
 */
function SectionTestimonialsComponent(props) {
  /** @type {GatsbyTypes.SectionTestimonialsQuery} */
  const {
    testimonialsData: {
      frontmatter: { companyLogos },
    },
  } = useStaticQuery(graphql`
    query SectionTestimonials {
      testimonialsData: markdownRemark(
        frontmatter: {
          identifier: { eq: "testimonials" }
          type: { eq: "staticSection" }
        }
      ) {
        frontmatter {
          companyLogos {
            logo {
              image {
                childImageSharp {
                  fluid(
                    maxWidth: 225
                    duotone: { highlight: "#9EAFCF", shadow: "#9EAFCF" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                ...FileInformation
              }
              alt
              caption
            }
          }
        }
      }
    }
  `);

  return (
    <SectionTestimonialsTemplate
      {...props}
      companyLogos={companyLogos.map(({ logo: { image, alt, caption } }) => ({
        image: image.childImageSharp,
        publicURL: image.publicURL,
        alt,
        caption,
      }))}
    />
  );
}

/**
 * @typedef {object} CompanyLogo
 * @prop {object} image
 * @prop {string} publicURL
 * @prop {string} alt
 * @prop {string} caption
 *
 * @param {GatsbyTypes.SectionTestimonialsDataFragment & { companyLogos: CompanyLogo[]}} props
 */
function SectionTestimonialsTemplate({ section_id, quotes, companyLogos }) {
  return (
    <section id={section_id} className={styles.testimonials}>
      <Carousel>
        {quotes.map((quote, index) => (
          <Quote key={`${section_id}-${index}`} align="center" {...quote} />
        ))}
      </Carousel>

      <div className={styles.logoContainer}>
        <Row
          justify="space-around"
          align="middle"
          gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 24]}
        >
          {companyLogos.map(({ image, publicURL, alt, caption }, index) => (
            <Col
              key={`companyLogos-${index}`}
              xs={24}
              sm={12}
              md={6}
              lg={Math.floor(24 / companyLogos.length)}
            >
              <Image
                className={styles.companyLogo}
                src={image}
                alt={alt || caption}
                title={caption || alt}
                publicURL={publicURL}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}
