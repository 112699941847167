import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import SEO, { createQueryImageFromFile } from "components/SEO";
import SectionFeatures from "components/SectionFeatures";
import SectionBlogSuggestions from "components/SectionBlogSuggestions";
import SectionTestimonials from "components/SectionTestimonials";
import SectionClientCases from "components/SectionClientCases";
import SectionCTA from "components/SectionCTA";
import SectionContent, { SectionHero } from "components/SectionContent";
import SectionLeadership from "components/SectionLeadership";
import SectionCollapsible from "components/SectionCollapsible";
import SectionContentCards from "components/SectionContentCards";

import SectionEbooks from "components/SectionEbooks";
import { SECTION_TYPES } from "shared/constants";

const DATE_PUBLISHED = new Date("2019-08-12T12:51:31+00:00");

export const pageQuery = graphql`
  fragment SectionData on MarkdownRemarkFrontmatterSections {
    ...SectionFeatureData
    ...SectionClientCasesData
    ...SectionTestimonialsData
    ...SectionCTAData
    ...SectionContentData
    ...SectionHeroData
    ...SectionLeadershipData
    ...SectionContentCardsData
    ...SectionCollapsibleData
    ...SectionEbooksData
    section_id
    type
  }

  query SectionsPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        dateModified
      }
      frontmatter {
        title
        description
        sharingImage {
          image {
            ...OpenGraphImage
            ...TwitterImage
            ...StructuredDataImage
          }
          alt
          caption
        }
        sections {
          ...SectionData
        }
      }
    }
  }
`;

const componentsMap = {
  [SECTION_TYPES.FEATURES]: SectionFeatures,
  [SECTION_TYPES.BLOG_SUGGESTIONS]: SectionBlogSuggestions,
  [SECTION_TYPES.CLIENT_CASES]: SectionClientCases,
  [SECTION_TYPES.TESTIMONIALS]: SectionTestimonials,
  [SECTION_TYPES.CALL_TO_ACTION]: SectionCTA,
  [SECTION_TYPES.CONTENT]: SectionContent,
  [SECTION_TYPES.HERO]: SectionHero,
  [SECTION_TYPES.LEADERSHIP]: SectionLeadership,
  [SECTION_TYPES.COLLAPSIBLE]: SectionCollapsible,
  [SECTION_TYPES.CONTENT_CARDS]: SectionContentCards,
  [SECTION_TYPES.EBOOKS]: SectionEbooks,
};

/**
 * @typedef {object} SectionsPageTemplateProps
 * @prop {GatsbyTypes.SectionDataFragment[]} sections
 *
 * @param {SectionsPageTemplateProps} props
 */
export const SectionsPageTemplate = ({ sections }) => {
  return (
    <React.Fragment>
      {sections.map((section, section_idx) => {
        const Component = componentsMap[section.type];
        if (!Component) return null;
        return <Component key={section_idx} {...section} />;
      })}
    </React.Fragment>
  );
};

export default function SectionsPage({
  location: { pathname },
  data: {
    markdownRemark: {
      frontmatter: { title: pageTitle, description, sharingImage, sections },
      fields: { dateModified },
    },
  },
}) {
  const [
    openGraph,
    twitter,
    sdPrimaryImage,
  ] = createQueryImageFromFile(sharingImage, [
    "openGraphImage.resize",
    "twitterImage.resize",
    "sdImage.resize",
  ]);

  return (
    <Layout gradientBackground>
      <SEO
        pathname={pathname}
        title={pageTitle}
        description={description}
        images={{ openGraph, twitter, sdPrimaryImage }}
        datePublished={DATE_PUBLISHED}
        dateModified={new Date(dateModified)}
      />
      <SectionsPageTemplate sections={sections} />
    </Layout>
  );
}
