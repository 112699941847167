import React from "react";
import { graphql } from "gatsby";
import { useMediaQuery } from "react-responsive";

import { PrimaryButton } from "components/Buttons";

import styles from "./SectionCTA.module.less";
import laptopWithEmsUrl from "./laptop_with_ems.svg";
import floatingWhiteCubeUrl from "./floating_white_cube.svg";

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.SectionCTADataFragment` below)
 */
export const SectionCTADataFragment = graphql`
  fragment SectionCTAData on MarkdownRemarkFrontmatterSections {
    text
    button {
      buttonText
      buttonUrl
    }
    section_id
  }
`;

/**
 * @param {GatsbyTypes.SectionCTADataFragment} props
 */
export default function SectionCTA({ text, button, section_id }) {
  const isScreenSmallerThanMedium = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const isScreenSmallerThanXLarge = useMediaQuery({
    query: "(max-width: 1200px)",
  });

  return (
    <section id={section_id} className={styles.callToAction}>
      <h2 className={styles.ctaHeader}>{text}</h2>
      {button && (
        <PrimaryButton
          className={styles.ctaButton}
          hasBoxShadow
          isContent
          title={button.buttonText}
          to={button.buttonUrl}
        />
      )}
      {!isScreenSmallerThanMedium && (
        <img
          className={styles.floatingWhiteCubeImage}
          src={floatingWhiteCubeUrl}
          alt="Floating white cube"
        />
      )}
      {!isScreenSmallerThanXLarge && (
        <img
          className={styles.laptopImage}
          src={laptopWithEmsUrl}
          alt="Laptop with EMS"
        />
      )}
    </section>
  );
}
